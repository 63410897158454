export function emailLoginErro() {
  let erro = "";
  const email = this.$v.login.email;
  if (!email.$dirty) {
    return erro;
  }
  if (!email.required) {
    erro = "E-mail é obrigatório";
  } else if (!email.email) {
    erro = "Insira um e-mail válido";
  }
  return erro;
}
export function senhaLoginErro() {
  let erro = "";
  const senha = this.$v.login.password;
  if (!senha.$dirty) {
    return erro;
  }
  if (!senha.required) {
    erro = "Senha é obrigatória";
  }
  return erro;
}
export function emailEsqueciErro() {
  let erro = "";
  const email = this.$v.emailEsqueci;
  if (!email.$dirty) {
    return erro;
  }
  if (!email.required) {
    erro = "E-mail é obrigatório";
  } else if (!email.email) {
    erro = "Insira um e-mail válido";
  }
  return erro;
}

export function emailSubscribe() {
  let erro = "";
  const email = this.$v.email;
  if (!email.$dirty) {
    return erro;
  }
  if (!email.required) {
    erro = "E-mail é obrigatório";
  } else if (!email.email) {
    erro = "Insira um e-mail válido";
  }
  return erro;
}

export function lgpdSubscribe() {
  let erro = "";
  const input = this.$v.lgpd;
  if (!input.$model) {
    erro = "Aceite os termos";
  }
  return erro;
}

export function recuperarSenhaErro() {
  let erro = "";
  const senha = this.$v.password;
  if (!senha.$dirty) {
    return erro;
  }
  if (!senha.required) {
    erro = "Senha é obrigatória";
  }
  return erro;
}


// Cadastrar
export function cadastroSenhaErro() {
  let erro = "";
  const field = this.$v.form.password;
  if (!field.$dirty) {
    return erro;
  }
  if (!field.required) {
    erro = "Senha é obrigatória";
  }
  return erro;
}


// Cadastrar
export function cadastroConfirmarSenhaErro() {
  let erro = "";
  const field = this.$v.form.password;
  const confirm = this.$v.form.confirmPassword;
  if (!field.$dirty && !!confirm.$dirty) {
    return erro;
  }
  if (!field.required) {
    erro = "Confirme a senha";
  }
  if (confirm.$dirty && field.$model !== confirm.$model) {
    erro = "As senhas não coincidem";
  }
  return erro;
}



// Cadastrar
export function cadastroTelefoneErro() {
  let erro = "";
  const field = this.$v.form.phonenumber;
  if (!field.$dirty) {
    return erro;
  }
  if (!field.required) {
    erro = "Telefone é obrigatório";
  }
  return erro;
}



// Cadastrar
export function cadastroNomeErro() {
  let erro = "";
  const field = this.$v.form.name;
  if (!field.$dirty) {
    return erro;
  }
  if (!field.required) {
    erro = "Nome é obrigatório";
  }
  return erro;
}



// Cadastrar
export function cadastroNomeEmpresaErro() {
  let erro = "";
  const field = this.$v.form.company_name;
  if (!field.$dirty) {
    return erro;
  }
  if (!field.required) {
    erro = "Nome da empresa é obrigatório";
  }
  return erro;
}