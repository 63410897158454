<template>
  <div class="login-main singnup">
    <b-row>
      <b-col md="5" lg="4" sm="12">
        <div class="modal-login">
          <div class="container-login-img"><LogoEmpresa /></div>
          <transition mode="out-in" name="fade-left" appear>
            <!-- Cadastre-se grátis -->
            <div class="container-senha" key="senha">
              <p>Finalizar cadastro</p>
              <inputValidate :erro="cadastroNomeEmpresaErro">
                <label for="f-Empresa">Empresa</label>
                <input
                  id="f-Empresa"
                  type="text"
                  v-model.trim="form.company_name"
                  @blur="$v.form.company_name.$touch()"
                  @keyup.enter="enterParaEnviar"
                  :disabled="hasCompany"
                  v-bind:class="$v.form.company_name.$invalid && $v.form.company_name.$dirty ? 'has-error' : ''"
                />
              </inputValidate>
              <inputValidate :erro="cadastroNomeErro">
                <label for="f-Nome">Nome</label>
                <input
                  id="f-Nome"
                  type="text"
                  v-model.trim="form.name"
                  @blur="$v.form.name.$touch()"
                  @keyup.enter="enterParaEnviar"
                   v-bind:class="$v.form.name.$invalid && $v.form.name.$dirty ? 'has-error' : ''"
                />
              </inputValidate>
              <inputValidate :erro="cadastroTelefoneErro">
                <label for="f-Telefone">Telefone</label>
                <input
                  id="f-Telefone"
                  type="tel"
                  v-mask="['(##) ####-#####']"
                  v-model.trim="form.phonenumber"
                  @blur="$v.form.phonenumber.$touch()"
                  @keyup.enter="enterParaEnviar"
                   v-bind:class="$v.form.phonenumber.$invalid && $v.form.phonenumber.$dirty ? 'has-error' : ''"
                />
              </inputValidate>
              <inputValidate :erro="cadastroSenhaErro">
                <label for="f-Senha">Senha</label>
                <input
                  id="f-Senha"
                  type="password"
                  v-model.trim="form.password"
                  @blur="$v.form.password.$touch()"
                  @keyup.enter="enterParaEnviar"
                   v-bind:class="$v.form.password.$invalid && $v.form.password.$dirty ? 'has-error' : ''"
                />
              </inputValidate>
              <inputValidate :erro="cadastroConfirmarSenhaErro">
                <label for="f-Senha2">Confirmar senha</label>
                <input
                  id="f-Senha2"
                  type="password"
                  v-model.trim="form.confirmPassword"
                  @blur="$v.form.confirmPassword.$touch()"
                  @keyup.enter="enterParaEnviar"
                   v-bind:class="$v.form.confirmPassword.$invalid && $v.form.confirmPassword.$dirty ? 'has-error' : ''"
                />
              </inputValidate>

              <b-button block variant="success" class="btn-enviar btn-enviar-signup" @click="enviar" :disabled="$v.form.$invalid">
                Cadastrar
              </b-button>
            </div>
          </transition>
        </div>
      </b-col>
      <b-col md="7" lg="8" sm="12" class="colored_side"></b-col>
    </b-row>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import {
  cadastroSenhaErro,
  cadastroConfirmarSenhaErro,
  cadastroTelefoneErro,
  cadastroNomeErro,
  cadastroNomeEmpresaErro,
} from "@/plugins/vuelidate/login";
import * as api from "@/api/login";
import inputValidate from "@/components/formulario/InputValidate.vue";
import LogoEmpresa from "@/components/logos/LogoPositivo.vue";
export default {
  name: "login",
  components: {
    inputValidate,
    LogoEmpresa,
  },
  data() {
    return {
      hasCompany: false,
      form: {
        token: null,
        company_name: null,
        name: null,
        phonenumber: null,
        password: null,
        confirmPassword: null
      },
    };
  },
  validations: {
    form: {
      company_name: {
        required,
      },
      name: {
        required,
      },
      phonenumber: {
        required,
      },
      password: {
        required,
      },
      confirmPassword: {
        required,
      },
    },
  },
  computed: {
    cadastroSenhaErro,
    cadastroConfirmarSenhaErro,
    cadastroTelefoneErro,
    cadastroNomeErro,
    cadastroNomeEmpresaErro,
  },
  methods: {
    enviar() {
      api.cadastro(this.form);
    },
    login() {
      this.$router.push({ path: "/login" });
    },
    enterParaEnviar() {
      // this.$v.password.$invalid ? false : this.enviarNovaSenha();
      if (!this.$v.form.$invalid) {
        this.enviar();
      }
    },
    checkToken() {
      api.verificarPreCadastro(this.form.token).then(data => {
        this.hasCompany = data.company;
        if (this.hasCompany) {
          this.form.company_name = data.company_name;
        }
      });
    },
  },
  mounted() {
    this.form.token = this.$route.params.token;
    this.checkToken();
  },
};
</script>

<style lang="scss" scoped>
.login-main {
  min-height: 100vh;
  padding: 0;
}
.colored_side {
  @include gradientRight;
  min-height: 100%;
}
.modal-login {
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding: 18px;
  max-width: 400px;
  margin: 0 auto;
  width: 100%;
  @include for-tablet-landscape-down {
    padding: 0 50px;
  }
  label {
    font-weight: 500;
    font-size: 12px;
  }
}
.login-links {
  padding: 24px;
  text-align: center;

  a {
    display: block;
    cursor: pointer;
    text-decoration: underline;
    color: $textos-login;
    margin-bottom: 6px;
    font-size: 14px;
    font-weight: 400;
  }
}
.container-login-img {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  width: 35%;
  img {
    width: 100%;
    max-width: 450px;
    height: auto;
    max-height: 250px;
    pointer-events: none;
    @media (max-width: 700px) {
      max-height: 150px;
    }
  }
}
.container-login,
.container-senha {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 250px;
  > div {
    width: 100%;
  }
  ul {
    color: $dark;
    font-size: 14px;
    margin-bottom: 30px;
    padding-left: 25px;

    li {
      position: relative;

      &:before {
        background: $red;
        content: "";
        height: 3px;
        left: -20px;
        position: absolute;
        top: 10px;
        transform: rotate(45deg);
        width: 5px;
      }

      &:after {
        background: $red;
        content: "";
        height: 3px;
        left: -18px;
        position: absolute;
        top: 9px;
        transform: rotate(-45deg);
        width: 13px;
      }
    }
  }
  p {
    font-size: 22px;
    width: 100%;
    margin: 20px 0 10px;
    color: $textos-login;
  }
}
.btn-enviar {
  font-weight: 500;
  padding: 10px;
}
.login-copy {
  width: 100%;
  text-align: center;
  p {
    font-size: 18px;
    color: $textos-login;
  }
}
</style>
